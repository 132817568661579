import moment from 'moment';
import $ from 'jquery';
import curry from 'lodash.curry';
import axios from 'axios';

import http from '@dbiqe/glu-core/src/http';
import util from '@dbiqe/glu-core/src/util';
import locale from '@glu/locale';

import constants from 'common/dynamicPages/api/constants';
import { dark } from 'common/util/featureUtil';
import services from 'services';
import userInfo from 'etc/userInfo';
import PaymentUtil from 'common/util/paymentUtil';
import validators from 'system/gluOverride/core/internal/validators';

export const DATE_LIST_FORMAT = {
    string: 'MM/DD/YYYY',
    array: 'YYYY-MM-DD',
    iso: 'YYYY-MM-DD',
};

export const dateFromFormat = curry((format, dateString) => moment(dateString, format));
export const dateFromServer = dateFromFormat(DATE_LIST_FORMAT.string);
export const dateFromIso = dateFromFormat(DATE_LIST_FORMAT.iso);
export const dateFromUser = (date, strictMode = false) =>
    moment(date, userInfo.getDateFormat(), strictMode);
export const dateToFormat = curry((format, date) => date.format(format));
export const dateToIso = dateToFormat(DATE_LIST_FORMAT.iso);
export const dateToMDY = dateToFormat(DATE_LIST_FORMAT.string);
export const dateToUser = date => date.format(userInfo.getDateFormat());
export const getMoment = date => (moment.isMoment(date) ? date : moment(date));
export const now = () => moment(new Date());
export const today = () => now().startOf('day');
/*
 * HACK NH-201970 We need an epic to address dates across the application. As a stop
 * gap, we are attempting to convert the date to the proper user format, when the date
 * from the server has a format that is similar to ISO.
 */
export const dateFromUserPossibly = (date) => {
    const userDate = dateFromUser(date, true);
    if (userDate.isValid()) {
        return userDate;
    }
    if (/\d{4}\D\d\d\D\d\d/.test(date)) {
        const newDate = moment(date);
        if (newDate.isValid()) {
            return newDate;
        }
    }
    // This is returning invalid, but at this point, we don't have any other options
    return userDate;
};

let lastDateListResponse;
let latestDateListPromise;

export default {
    PAYMENT_SUMMARY_DATE_FORMAT: 'D MMM YYYY',

    DATE_LIST_FORMAT,


    // Reusable moment functions for consistency
    dateFromFormat,
    dateFromIso,
    dateFromServer,
    dateFromUser,
    dateToFormat,
    dateToIso,
    dateToMDY,
    dateToUser,
    getMoment,
    now,
    today,

    getLatestRequestPromise() {
        return (latestDateListPromise ?? Promise.resolve());
    },

    handleDateListResponse(result, widgetModel, widget) {
        const newValueDate = dateFromServer(result.userSetValueDate ?? result.defaultDay);
        const earliestDate = dateFromServer(result.earliestDay);
        const newTranDate = dateFromServer(result.tranDate);

        widget.processingDates.daysForward.shift();
        widget.processingDates.daysForward.push(result.maxForwardDays);

        widget.processingDates.processingDays.shift();
        widget.processingDates.processingDays.push(result.businessDays);

        widget.processingDates.cutOffTimes.shift();
        widget.processingDates.cutOffTimes.push(result.cutoff);

        // eslint-disable-next-line no-param-reassign
        widget.processingDates.latestDay = result.latestDay;

        // display cutoff if returned and config allows
        if (result.cutoffDateTimeTz && PaymentUtil.isReadyValueDateRetrieval(widgetModel)) {
            widgetModel.set('CUTOFF_INFO', result.cutoffDateTimeTz);
        }

        // remove previous blocked dates
        widget.processingDates.blockedDates.splice(0, widget.processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            widget.processingDates.blockedDates.push(...result.holidays);
        }

        // Get value date widget in the (parent) view, or fall back to the input date
        const $valueDate = $('[data-hook="value-date"], [data-hook="getInputDate"]').first();

        if ($valueDate.length === 0) {
            return;
        }

        if (PaymentUtil.isReadyValueDateRetrieval(widgetModel)) {
            widgetModel.set('VALUE_DATE', dateToUser(newValueDate));
            // make sure the UI values are updated
            $('[name="VALUE_DATE"]').val(dateToUser(newValueDate));

            /*
             * NH-163757 - If the release lead time configuration is active, the TRAN_DATE will be
             * handled by the tranDateRangeWidget and should not be manually updated here
             */
            if (!dark.isLive('ENHANCEDWIREDATE')) {
                widgetModel.set('TRAN_DATE', dateToUser(newTranDate));
                $('[name="TRAN_DATE"]').val(dateToUser(newTranDate));
            }
        }

        /*
         * HACK: NH-63018 - directly update our datepicker input field after
         *  we get holidays (ui hash isn't available)
         */
        const $dateInputField = $('[name="VALUE_DATE"]');

        const isDayOffDate = this.isDayOff(
            widget.processingDates.processingDays[0],
            widget.processingDates.blockedDates,
            dateFromUser($dateInputField.val()),
        );

        if (isDayOffDate) {
            $dateInputField.val(dateToUser(earliestDate));
        }

        // Update calendars after all the dates are set
        this.updateCalendars($valueDate, {
            blockedDates: widget.processingDates.blockedDates,
            daysBack: today().diff(earliestDate, 'days'),
            daysForward: widget.processingDates.daysForward[0],
            latestDay: result.latestDay,
            startDate: earliestDate,
        });
    },

    /**
     * Handles the logic to update value date and transaction date for
     * trnaDateRangeWidget and ValueDateWidget
     */

    handleDateListCallForWidgets(widgetModel, widget) {
        const savedDefaultDate = dateFromServer(lastDateListResponse?.defaultDay);
        const savedDefaultTranDate = dateFromServer(lastDateListResponse?.defaultTranDate);
        const currentValueDate = dateFromUserPossibly(widgetModel.get('VALUE_DATE'));
        const currentTranDate = dateFromUserPossibly(widgetModel.get('TRAN_DATE'));
        const isCurrentDefault = currentValueDate.isSame(savedDefaultDate)
        && currentTranDate.isSame(savedDefaultTranDate);

        // Don't submit known bad data. It will throw errors. Wait for the user to fix it.
        const datesValid = (widgetModel.get('VALUE_DATE') === '' || currentValueDate.isValid())
            && (widgetModel.get('TRAN_DATE') === '' || currentTranDate.isValid());
        if (!datesValid && !isCurrentDefault) {
        /*
         * Instead of rejecting, resolving it so that we can avoid widgetPromises
         * to get rejected in metaDrivenForm.js
         */
            return Promise.resolve();
        }

        const postData = {
            paymentType: widgetModel.jsonData.typeInfo.typeCode,
            debitBank: widgetModel.get('DEBIT_BANK_CODE'),
            debitCurrency: widgetModel.get('DEBIT_CURRENCY'),
            debitBankCountry: widgetModel.get('DEBIT_COUNTRY'),
            debitBankAccount: widgetModel.get('DEBIT_ACCOUNT_NUMBER'),
            subType: widgetModel.jsonData.subtype ?? '*',
            creditCurrency: widgetModel.get('CREDIT_CURRENCY'),
            creditBankCountry: widgetModel.get('BENE_BANK_COUNTRY'),
            beneBankId: widgetModel.get('BENE_BANK_ID'),
            beneBankType: widgetModel.get('BENE_BANK_TYPE'),
            releaseLeadTime: widgetModel.get('RELEASELEADTIME'),
            valueDate: isCurrentDefault ? '' : widgetModel.get('VALUE_DATE'),
            tranDate: isCurrentDefault ? '' : widgetModel.get('TRAN_DATE'),
            preAdviseWires: widgetModel.get('PREADVISEWIRES'),
        };

        if (widget.currentAbortController) {
            widget.currentAbortController.abort();
        }
        // eslint-disable-next-line no-param-reassign
        widget.currentAbortController = new AbortController();

        latestDateListPromise = axios.post(
            services.generateUrl(constants.URL_GETDATESLIST),
            postData,
            { signal: widget.currentAbortController.signal },
        ).then((response) => {
            lastDateListResponse = response.data;
            if (widget.widgetID === 'fxpaymentwidget') {
                this.handleDateListResponse(lastDateListResponse, widgetModel, widget);
            }

            return lastDateListResponse;
        }).catch(err => Promise.reject(err));
        return latestDateListPromise;
    },

    overrideDateValidator() {
        validators.matchesDatePattern = (...args) => this.dateValidation(...args);
    },

    dateValidation(key, pat, model) {
        let pattern = pat;
        if (util.isEmpty(model.get(key))) {
            return true;
        }
        if (util.isFunction(pattern)) {
            pattern = pattern(key, pattern, model).toUpperCase();
        } else if (util.isString(pattern)) {
            pattern = pattern.toUpperCase();
        } else {
            pattern = userInfo.getDateFormat();
        }

        if (model?.fieldData?.[key]?.fieldUIType === 'DATEFILTER') {
            const dateList = model.get(key).split(' - ');
            const startDate = moment(dateList[0], pattern);
            const endDate = moment(dateList[1], pattern);

            return startDate.isValid() && endDate.isValid();
        }
        const d = moment(model.get(key), pattern, true);
        return d.isValid();
    },
    /**
     * Updates the days logic
     * @param {Object} result - AJAX response, probably from URL_GETDATESLIST
     * @param {Object} form - Parent containing the view
     * @param {string} dateId - The DOM element ID of the related datepicker input.
     * @param {boolean} updateDate
     */
    handleBusinessDaysResponse(result, form, dateId, updateDate) {
        const { model } = form.formView;
        const dates = form.formView.processingDates;
        const state = form.formView.state.toUpperCase();
        const paymentType = model.get('TYPE');
        const isACH = !['RTGS', 'RTP'].includes(model.jsonData.typeInfo.productCode);
        const isEFT = paymentType?.toUpperCase()?.includes('EFT');
        const isUserSetDateInvalid = result.userSetValueDate && result.userSetDateInvalid;
        const defaultDate = dateFromFormat(DATE_LIST_FORMAT.string, result.defaultDay);
        const earliestDay = dateFromFormat(DATE_LIST_FORMAT.string, result.earliestDay);
        let defaultDay = defaultDate;

        PaymentUtil.invalidDateToggle(
            $('.ui-datepicker-trigger'),
            isUserSetDateInvalid,
            false,
        );

        /*
         * NH-152833
         * If default date is more than the max amount of forward days for ACH types
         * then we use the ACH max date for the defaultDate
         */
        if (isACH) {
            const maxDateACH = now().add(result.maxForwardDays, 'days');
            defaultDay = defaultDay.isAfter(maxDateACH) ? maxDateACH : defaultDay;
        }

        /*
         * Remove old values and insert new ones without replacing the array.
         * TODO - find out if this is really needed
         */
        dates.daysForward.shift();
        dates.daysForward.push(result.maxForwardDays);

        dates.processingDays.shift();
        dates.processingDays.push(result.businessDays);

        dates.cutOffTimes.shift();
        dates.cutOffTimes.push(result.cutoff);

        dates.earliestDay = result.earliestDay;
        dates.latestDay = result.latestDay;

        // display cutoff if returned and config allows
        if (result.cutoffDateTimeTz && state !== 'VIEW') {
            model.set('CUTOFF_INFO', result.cutoffDateTimeTz);
            PaymentUtil.showCutoff(
                result.cutoffDateTimeTz,
                $('.ui-datepicker-trigger'), paymentType, state, model.get('STATUS'),
            );
        }

        dates.blockedDates.splice(0, dates.blockedDates.length);
        if (result.holidays.length > 0) {
            dates.blockedDates.push(...result.holidays);
        }
        const dateFormElement = form.formView.$el.find(`#${dateId}`);
        if (updateDate === true || isUserSetDateInvalid) {
            form.formView.model.set(dateId, dateToUser(defaultDate));
            $(`#${dateId}`).val(dateToUser(defaultDate));
            $(`#${dateId}`).trigger('change');
        }
        this.updateCalendars(
            dateFormElement,
            {
                blockedDates: dates.blockedDates,
                defaultDay: dateToUser(defaultDay),
                minDaysForward: result.minDaysForward,
                daysForward: dates.daysForward[0],
                daysBack: (result.maxBackwardDays > 0
                    ? result.maxBackwardDays : (result.maxBackwardDays * -1)),
                isACH,
                latestDay: result.latestDay,
                earliestDay: isEFT ? null : dateToUser(earliestDay),
            },
        );
    },

    /**
     * Requests date
     * @param {Object} form
     * @param {String} dateId - The DOM element ID of the related datepicker input.
     * @param {Object} postData - Data to submit ot the getDatesList endpoint
     * @returns {Promise} returns the promise of the http.post, in case we want it.
     */
    setEffectiveDate(form, dateId, postData) {
        const dateService = services.generateUrl(constants.URL_GETDATESLIST);

        // Return the promise, just in case.
        return http.post(dateService, postData, (result) => {
            this.handleBusinessDaysResponse(result, form, dateId, true);
        }, () => {
            // on error do nothing for now
        });
    },

    /**
     * Requests a datepicker configuration and optionally updates the date of a datepicker
     * @param {Object} form - Form containing the date picker to be updated
     * @param {type} dateId - DOM element id of the datepicker input
     * @param {type} postData - Data to submit to the date service.
     * @param {type} updateIfStale - If true, update the datepicker input if it is out of date,
     *                  otherwise just refresh the datepicker configuration.
     * @returns {Promise}  returns the promise of the http.post, in case we want it.
     */
    refreshEffectiveDateCalendar(form, dateId, postData, updateIfStale) {
        const dateService = services.generateUrl(constants.URL_GETDATESLIST);

        // Return the promise, just in case.
        return http.post(dateService, postData, (result) => {
            let updateDate = false;

            if (updateIfStale) {
                const dateValue = form.formView.model.get(dateId);
                const currentValueDate = moment(dateValue, userInfo.getDateFormat());
                const earliestDate = result.onUs
                    ? today().add(result.minDaysForward, 'day')
                    : dateFromFormat(DATE_LIST_FORMAT.string, result.earliestDay);
                updateDate = currentValueDate < earliestDate;
            }

            this.handleBusinessDaysResponse(result, form, dateId, updateDate);
        }, () => {
            // on error do nothing for now
        });
    },

    /**
     * Update the model
     * @param {Object} model
     * @param {jQuery} $datepicker - jQuery input for the datepicker
     * @param {Boolean} [justDates] - Optional setting to prevent using the labels
     */
    updateModelDates(model, $datepicker, justDates) {
        const { separator } = $datepicker.data('daterangepicker');

        // does the datepicker always use userInfo.getDateFormat format?
        const rangeString = $datepicker.val();

        const parts = rangeString.split(separator);
        const chosenLabel = $datepicker.data('daterangepicker').chosenLabelOrig;

        const dateKey = util.findKey(this.getDateCodes(), val => val === chosenLabel);

        // Always reset the end date.
        model.set({
            END_DATE: '',
        });

        if (!justDates && dateKey) {
            model.set({
                START_DATE: dateKey,
            });
        // Custom Range or "justDates"
        } else if (parts.length === 1) {
            model.set({
                START_DATE: parts[0],
                END_DATE: parts[0],
            });
        } else if (parts.length === 2) {
            model.set({
                START_DATE: parts[0],
                END_DATE: parts[1],
            });
        }
    },
    /**
     * Get the date codes for labels and calculation
     * @returns {Object}
     */
    getDateCodes() {
        return {
            L7D: locale.get('common.datepicker.last7Days'),
            L30D: locale.get('common.datepicker.last30Days'),
            L60D: locale.get('common.datepicker.last60Days'),
            L90D: locale.get('common.datepicker.last90Days'),
            MTD: locale.get('common.datepicker.monthToDate'),
            QTD: locale.get('common.datepicker.quarterToDate'),
            YTD: locale.get('common.datepicker.yearToDate'),
            CD: locale.get('common.datepicker.today'),
            PBD: locale.get('common.datepicker.priorDay'),
            LM: locale.get('common.datepicker.lastMonth'),
            PD: locale.get('common.datepicker.priorDay'),
            PMS: locale.get('common.datepicker.lastMonth'),
            CMS: locale.get('common.datecode.CMS'),
            CWS: locale.get('common.datecode.CWS'),
            CYS: locale.get('common.datecode.CYS'),
            ND: locale.get('common.datecode.ND'),
            PME: locale.get('common.datecode.PME'),
            PWE: locale.get('common.datecode.PWE'),
            PWS: locale.get('common.datecode.PWS'),
            PYE: locale.get('common.datecode.PYE'),
            PYS: locale.get('common.datecode.PYS'),
            PTW: locale.get('common.datecode.PTW'),
        };
    },

    /**
     * checks if date falls on weekends
     * TODO support bank holidays like PBD filter on server side
     * TODO support weekends that are not Saturday/Sunday
     * @param {moment} date
     * @returns {boolean} false if date falls on sat or sun, true otherwise
     */
    isWorkDay(date) {
        const day = date.toDate().getDay();
        return day !== 0 && day !== 6;
    },

    /**
     * Check a date to see if it is in the past, relative to now
     *
     * @param {moment|Date|string} date - date to check against 'now'
     * @param {moment|Date|string} [relativeNow] - used as relative 'now'
     * @returns {boolean}
     * FIXME: No formats for these dates
     */
    isValidPastDate(date, relativeNow = now()) {
        const first = getMoment(date);
        const second = getMoment(relativeNow);

        return first.diff(second, 'days') < 0;
    },

    /**
     * TODO support bank holidays like PBD filter on server side
     * @param {moment|Date|string} [startDate] - Optional date from which to work.
     * @returns {moment} prior day. if prior day on a weekend returns prior non weekend date
     * FIXME: No formats for these dates
     */
    getPriorDate(startDate) {
        const fromDate = moment(startDate || new Date());
        const prevBusinessDate = fromDate.subtract(1, 'day');

        while (!this.isWorkDay(prevBusinessDate)) {
            prevBusinessDate.subtract(1, 'day');
        }
        return prevBusinessDate;
    },

    /**
     * Check for explicit date codes and return the appropriate object.
     * Otherwise, pass the input through.
     *
     * @param {string} date
     * @return {string|moment}
     */
    convertRangeCodesToDates(date) {
        switch (date) {
        case 'L7D':
            return dateToUser(now().subtract(7, 'day'));
        case 'L30D':
            return dateToUser(now().subtract(30, 'day'));
        case 'L60D':
            return dateToUser(now().subtract(60, 'day'));
        case 'L90D':
            return dateToUser(now().subtract(90, 'day'));
        case 'MTD':
            return dateToUser(now().startOf('month'));
        case 'QTD':
            return dateToUser(now().startOf('quarter'));
        case 'YTD':
            return dateToUser(now().startOf('year'));
        case 'CD':
            return dateToUser(today());
        case 'PBD':
            return dateToUser(this.getPriorDate());
        default:
            // if the date didn't match any code, pass it through
            return date;
        }
    },

    /**
     * Determine a day off based on available data.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {array} holidays - array of optional holidays, formatted as moment objects
     * @param {moment} testDate - the date to check, as a moment object.
     * @return {boolean}
     */
    isDayOff(businessDays, holidays, testDate) {
        const dayOfWeek = businessDays[testDate.day()];
        const isNotWorkDay = dayOfWeek === 0 || dayOfWeek === '0';

        const isHoliday = util.some(holidays, date => testDate.isSame(date, 'day'));

        return isNotWorkDay || isHoliday;
    },

    /**
     * Convert all days in an array to moment objects
     * @param {array} dateArray
     * @return {array}
     * FIXME: No formats for these dates
     */
    getArrayOfMoments(dateArray) {
        return util.map(dateArray, date => moment(date));
    },

    /**
     * Adjust a "max days" to compensate for non-business days.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {array} holidays - array of optional holidays, formatted as strings
     * @param {Date|moment|string} startDate - the date from which we calculate the max days.
     * @param {number} daysOut - The starting set of days out.
     * @return {number}
     */
    adjustMaxDays(businessDays, holidays, startDate, daysOut) {
        // FIXME: No format for starDate
        const fromDate = getMoment(startDate);
        const busDaysArray = Array.isArray(businessDays) ? businessDays : businessDays.split('');
        const momentHolidays = this.getArrayOfMoments(holidays);
        const isADayOff = this.isDayOff.bind(this, busDaysArray, momentHolidays);
        let maxDays = daysOut;

        // If there are no business days in the businessDays, we would run forever, so don't try
        if (busDaysArray.indexOf(1) === -1 && busDaysArray.indexOf('1') === -1) {
            return maxDays;
        }

        for (let i = 0; i < maxDays; i += 1) {
            /*
             * Wrap the fromDate in a new moment() or you mutate it,
             * which is bad for accuracy.
             */
            if (isADayOff(moment(fromDate).add(i, 'days'))) {
                // Increase the maxDays every time we hit a non business day.
                maxDays += 1;
            }
        }

        return maxDays;
    },

    /**
     * Find the first business day, going forward or back, inclusive of the startDate.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {string[]} holidays - array of optional holidays, formatted as strings
     * @param {Date|moment|string} startDate - the date from which we calculate the max days.
     * @param {boolean} [decrement]
     * @return {*}
     */
    getFirstBusinessDay(businessDays, holidays, startDate, decrement) {
        // Always wrap in a new moment because we will mutate it.
        const fromDate = moment.utc(startDate);
        const momentHolidays = this.getArrayOfMoments(holidays);
        const busDaysArray = util.isArray(businessDays) ? businessDays : businessDays.split('');
        const isADayOff = this.isDayOff.bind(this, busDaysArray, momentHolidays);
        let daysTried = 0;

        while (isADayOff(fromDate) && daysTried < 45) {
            fromDate.add(decrement ? -1 : 1, 'day');
            daysTried += 1;
        }

        // If we didn't find any in 1.5 months, return the original date.
        if (daysTried === 45) {
            return startDate;
        }

        return fromDate;
    },

    /**
     * Properly process cutoff times
     * @param {string} cutoffString - The cutoff time string.
     * @param {string|Date|moment} testDate - the date to test. We only check the date of this,
     * not the time.
     * @param {string|Date|moment} [relativeNow] - Value for now. Defaults to now if not passed.
     * @return {boolean}
     */
    isAfterCutoff(cutoffString, testDate, relativeNow) {
        const currentMoment = relativeNow ? moment(relativeNow) : now();
        const testMoment = getMoment(testDate);

        /*
         * FIXME: cutoff is a relative time, so time zones could be problematic.
         *  Cutoff should be replaced with an exact datetime in the future.
         */
        const cutoff = (cutoffString || '').split(':').map(val => (val !== undefined ? parseInt(val, 10) : val));

        const cutoffHour = cutoff[0];
        const cutoffMinute = cutoff[1];
        const currentHour = currentMoment.hour();
        const currentMinute = currentMoment.minute();

        // If the cutoff time is 00:00, it's disabled
        const isCutoffValid = cutoffHour !== undefined
             && cutoffMinute !== undefined && (cutoffHour + cutoffMinute !== 0);

        // Cutoff only matters if we are on today.
        const isSameDay = currentMoment.isSame(testMoment, 'day');

        const isAfterCutoff = (currentHour > cutoffHour
            || (currentHour === cutoffHour && currentMinute >= cutoffMinute));

        return isCutoffValid && isSameDay && isAfterCutoff;
    },

    /**
     * return the earlier of two dates
     *
     * @param {moment|Date|string} date1
     * @param {moment|Date|string} date2
     * @returns {boolean}
     */
    getEarlierDate(date1, date2) {
        const first = getMoment(date1);
        const second = getMoment(date2);

        return first.isBefore(second) ? date1 : date2;
    },

    /**
     * return the last available date from the right calendar and set it like the end date
     * @param {Array} calendar
     * @param {function} isInvalidDay - validator for invalidness
     * @returns {moment}
     */
    getLastAvailableDate(calendar, isInvalidDay) {
        let lastValidDay = now();

        for (let row = 0; row < calendar.length; row += 1) {
            for (let col = 0; col < calendar[row].length; col += 1) {
                const day = calendar[row][col];
                lastValidDay = !isInvalidDay(day) ? day : lastValidDay;
            }
        }

        return lastValidDay;
    },

    /**
     * adds a date mask to the date field
     * @param {object} view - view where date field resides
     * @param {string} dateFieldName - name of date field
     */
    maskDate: (view, dateFieldName) => {
        const selector = `[name="${dateFieldName}"]`;
        if (view.$(selector).length > 0) {
            view.$(selector).inputmask({
                mask: userInfo.getDateFormat().replace(/[^-, /]/g, '9'),
                placeholder: userInfo.getDateFormat(),
                onUnMask: (maskedValue) => {
                    if (maskedValue !== userInfo.getDateFormat()) {
                        return maskedValue;
                    }
                    return '';
                },
            });
        }
    },

    /**
     * Check for an existing dateRangePicker. If one is available, call updateCalendars
     *  followed by updating the DRP from the input, ensuring we have the correct date.
     * @param {jquery|daterangepicker} datePicker
     * @param {Object} [options] - daterangepicker.updateCalendar options
     *  If not provided the .updateCalendar() call does basically nothing.
     *  Useful in some cases to update the value but not the calendar options.
     */
    updateCalendars(datePicker, options) {
        const dateRangePicker = datePicker?.jquery ? datePicker.data('daterangepicker') : datePicker;
        if (!dateRangePicker?.updateCalendars) {
            // TODO - real error logging for missing datepicker?
            return;
        }
        dateRangePicker.updateCalendars(options);
        // Different versions of the datepicker have different methods, so try both.
        dateRangePicker.updateFromControl?.();
        dateRangePicker.elementChanged?.();
    },

    setCustomDates(datePicker, startDate, endDate) {
        const dateRangePicker = datePicker?.jquery ? datePicker.data('daterangepicker') : datePicker;
        if (!dateRangePicker?.setCustomDates) {
            // TODO - real error logging for missing datepicker?
            return;
        }
        dateRangePicker.setCustomDates(startDate, endDate);
    },

    getDisplayDateTimestamp(dateTimeToFormat) {
        const formattedDate = moment(dateTimeToFormat, [userInfo.getDateTimeFormat(), 'YYYY-MM-DD HH:mm:ss.S']).format(userInfo.getDateTimeFormat());
        return formattedDate;
    },
};
